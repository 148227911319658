.scrollBar {
	height: calc(100vh - 315px);
}
.plate {
	max-width: 876px;
}
.plateContent {
	padding-left: 64px;
	padding-right: 0px;
	padding-top: 40px;
}
.container {
	max-height: 100%;
	padding-right: 64px;
}
