@import (reference) '~@tehzor/ui-components/src/styles/colors';
@import (reference) '~@tehzor/ui-components/src/styles/typography';

.title {
	margin: 0;
	margin-bottom: 24px;
	color: @black4;
	.d-semibold-18();
}

.form {
	display: grid;
	grid-template-columns: 1fr 1fr;
	column-gap: 40px;
	row-gap: 26px;
}

.formItem {
	max-width: 354px;
}
